import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet';

// Components
import Header from '../components/layout/Header';
import Footer2 from '../components/layout/Footer2';
import BannerImg from '../assets/images/gallery/BannerImg.webp';
import BannerImgMB from '../assets/images/gallery/BannerImgMB.webp';
import Banner from '../components/banners/Banner';
import FsLightbox from "fslightbox-react";
import Icon1 from '../assets/images/gallery/Icon1.webp';
import Icon2 from '../assets/images/gallery/Icon2.webp';
import Icon3 from '../assets/images/gallery/Icon3.webp';
import Icon4 from '../assets/images/gallery/Icon4.webp';
import Icon5 from '../assets/images/gallery/Icon5.webp';
import GallaryHeader from '../components/gallary/GallaryHeader';
import SliderAboveFooter from '../components/home/SliderAboveFooter';
import Img1 from '../assets/images/gallery/GalleryBanner/1.webp';
import Img2 from '../assets/images/gallery/GalleryBanner/2.webp';
import Img3 from '../assets/images/gallery/GalleryBanner/3.webp';
import Img4 from '../assets/images/gallery/GalleryBanner/4.webp';
import Img5 from '../assets/images/gallery/GalleryBanner/5.webp';
import Img6 from '../assets/images/gallery/GalleryBanner/6.webp';
import Img7 from '../assets/images/gallery/GalleryBanner/7.webp';
import Img8 from '../assets/images/gallery/GalleryBanner/8.webp';
import Img9 from '../assets/images/gallery/GalleryBanner/9.webp';
import Img10 from '../assets/images/gallery/GalleryBanner/10.webp';
import Img11 from '../assets/images/gallery/GalleryBanner/11.webp';
import Img12 from '../assets/images/gallery/GalleryBanner/12.webp';
import Slider from 'react-slick';
// Sources

const Gallery = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
  }
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
const [toggler, setToggler] = useState(false);
const [lightboxController, setLightboxController] = useState({
  toggler: false,
  slide: 1
});

function openLightboxOnSlide(number) {
  setLightboxController({
    toggler: !lightboxController.toggler,
    slide: number
  });
}
  return (
    <>
    <Helmet>
      <title>
        Gallery - Northlodge Boutique Residences
      </title>
    </Helmet>
    <Header/>
    <Banner
    BannerImg={BannerImg}
    BannerImgMB={BannerImgMB}
    />


<div className="py-md-5 py-4"/>
        <section id='website-responsive' className="container-fluid">
          <div className="row justify-content-center align-items-center">
            <div className="col-11 col-lg-10 col-xl-8 text-center">
              <div className="">
                <div className='fs-ss-35 fw-700 text-californian lh-sm pt-3'>
                Beyond Comfort, Beyond Luxury <br className="d-none-d-md-block" /> Enter the Realm of True Elegance
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="py-2"/>    
        <GallaryHeader/>
        <div className="py-5"/>
        <section id='website-responsive' className="container-fluid px-0">
      <div id="home-banner" className="">
        <Slider {...settings}>
    <img className='w-98 shadow-ss-1 mx-auto' src={Img1} alt="" />
    <img className='w-98 shadow-ss-1 mx-auto' src={Img2} alt="" />
    <img className='w-98 shadow-ss-1 mx-auto' src={Img3} alt="" />
    <img className='w-98 shadow-ss-1 mx-auto' src={Img4} alt="" />
    <img className='w-98 shadow-ss-1 mx-auto' src={Img5} alt="" />
    <img className='w-98 shadow-ss-1 mx-auto' src={Img6} alt="" />
      <img className='w-98 shadow-ss-1 mx-auto' src={Img7} alt="" />
      <img className='w-98 shadow-ss-1 mx-auto' src={Img8} alt="" />
      <img className='w-98 shadow-ss-1 mx-auto' src={Img9} alt="" />
      <img className='w-98 shadow-ss-1 mx-auto' src={Img10} alt="" />
      <img className='w-98 shadow-ss-1 mx-auto' src={Img11} alt="" />
      <img className='w-98 shadow-ss-1 mx-auto' src={Img12} alt="" />
      </Slider>
      </div>
    </section>
    <div className="py-5 my-md-3"/>
        <section id='website-responsive' className="container-fluid">
          <div className="row justify-content-center align-items-center">
            <div className="col-11 col-lg-10 col-xl-8 text-center">
              <div className="">
                <div className='fs-ss-35 fw-700 text-californian lh-sm'>
                  Experience the Comforts of Home
                  <br className="d-none-d-md-block" /> Exclusive Benefits of Northlodge Boutique Residences! 
                </div>
                <div className="fs-ss-16 fw-400 pt-4 pt-md-5">
                  Indulge in the exquisite benefits of Northlodge Boutique Residences, where exclusive access is just the beginning of your luxurious experience. Family gatherings at our rooftop BBQ lounge offer a panoramic city view and delectable grilling options for an unforgettable dining experience. In addition, you can rejuvenate your senses on our open terrace, where serene moments await amidst lush surroundings and captivating vistas.
                  <div className="py-2" />
                  With our EV charging point, sustainability meets convenience, ensuring your eco-friendly lifestyle is seamlessly integrated into your stay. And when it's time to celebrate life's special moments, our party rooms provide the perfect venue to create lasting memories with your loved ones. At Northlodge Boutique Residences, these exceptional amenities await, promising a stay defined by luxury, comfort, and unparalleled hospitality.
                </div>
              </div>
            </div>
          </div>
          <div className="py-md-5 py-4"/>
          <div className="row justify-content-center text-center px-2 gy-4">
          <div className="col-4 col-lg-2">
            <div className="px-2">
            <img src={Icon1} alt="" className="w-100 px-md-5" />
            </div>
            <div className='fs-ss-18 fw-600 text-poppins lh-sm pt-3'>
            Rooftop BBQ <br/> Lounge
            </div>
          </div>

          <div className="col-4 col-lg-2">
          <div className="px-2">
            <img src={Icon2} alt="" className="w-100 px-md-5" />
            </div>            <div className='fs-ss-18 fw-600 text-poppins lh-sm pt-3'>
              Fitness Center
            </div>
          </div>

          <div className="col-4 col-lg-2">
          <div className="px-2">
            <img src={Icon3} alt="" className="w-100 px-md-5" />
            </div>            <div className='fs-ss-18 fw-600 text-poppins lh-sm pt-3'>
              Open Terrace 
            </div>
          </div>

          <div className="col-4 col-lg-2">
          <div className="px-2">
            <img src={Icon4} alt="" className="w-100 px-md-5" />
            </div>            <div className='fs-ss-18 fw-600 text-poppins lh-sm pt-3'>
            EV Charging <br/> Point
            </div>
          </div>

          <div className="col-4 col-lg-2">
          <div className="px-2">
            <img src={Icon5} alt="" className="w-100 px-md-5" />
            </div>
            <div className='fs-ss-18 fw-600 text-poppins lh-sm pt-3'>
              Party Room
            </div>
          </div>
          </div>

        </section>
        <div className="py-md-5 py-4"/>
        <Footer2/>
    </>
  );
}
export default Gallery;
