import React from 'react';
import HeaderLogo from '../../assets/images/home/HeaderLogo.webp';
import BottomHeader from './BottomHeader';
import TopHeader from './TopHeader';
import MobileHeader from './MobileHeader';

// Sources


const Header = () => {
  return (
    <>
    <TopHeader/>
    <section className="container-fluid position-header px-0 bg-ss-primary d-none d-lg-block pb-2">
    <section className="container-fluid pt-4 pb-4">
      <div className="row justify-content-center pb-3">
        <div className="col-3">
          <img className='w-100' src={HeaderLogo} alt="Header Logo" />
        </div>
      </div>
    </section>
    <BottomHeader/>
    </section>

    <section className="container-fluid px-0 d-block d-lg-none">
    <MobileHeader/>
    </section>
    </>
  );
}

export default Header;
