import React from 'react';
import styled, { keyframes } from "styled-components";
// Sources
const TopHeader = () => {
  
  return (
    <>
        <div className='container-fluid' style={{overflow:"hidden"}}>
            {/* <marquee className="text-white" onMouseOver="" direction="left">
                <strong>Get 5% more discount PROMO CODE: "LIVE5" Valid till May 31st, 2023</strong>
            </marquee> */}
            <div className='row'>
                <div className="col-12">
                <Marquee>
                    <div className='text-ss-primary fw-600'>
                    Register Now and Get VIP Access
                    </div>
                </Marquee>
                </div>
            </div>
        </div>
        </>
    );
}

const movingText = keyframes`
    0%{
    transform:translateX(100%);
    }
    100%{
    transform:translateX(-60%);
    }
`;

const Marquee = styled.p`
  @media (prefers-reduced-motion: no-preference) {
    padding: 0.3rem;
    margin: 0;
    animation: ${movingText} 20s linear infinite;
    :hover {
      animation-play-state: paused;
    }
  }
  cursor: default;
`;
export default TopHeader;
