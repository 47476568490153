import React, { Component } from "react";
import Slider from "react-slick";
import {Link} from 'react-router-dom';

export default class Responsive extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      pauseOnHover: false
    };
    return (
      <>
      <section id='website-responsive' className="container-fluid px-0 position-parent">
      <div id="home-banner" className="">
        <Slider {...settings} className="">
        <section  className="container-fluid bg-home-banner bg-home1"/>
        <section  className="container-fluid bg-home-banner bg-home2"/>
        <section  className="container-fluid bg-home-banner bg-home3"/>
        <section  className="container-fluid bg-home-banner bg-home4"/>
        <section  className="container-fluid bg-home-banner bg-home5"/>
        <section  className="container-fluid bg-home-banner bg-home6"/>
        </Slider>
      </div>
      <div className="text-center col-11 col-md-6 mx-auto position-child">
        <div className="text-ss-primary bg-ss-secondary-light px-4 py-2 fs-ss-48 text-banner fw--home-banner">
        Discover True Prestige 
        </div>
        <div className='pt-md-3 pt-2' />
        <Link className='text-decoration-none' to="/register">
          <button className="fs-ss-22 hover-golden shadow-lg mt-3 px-4 py-1 fw-500 text-white border-0">
            Register Now
          </button>
        </Link>
        </div>
        </section>
      </>
    );
  }
}