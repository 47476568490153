import React,{useEffect} from 'react';
import Aos from 'aos'

// Sources

const BelowBanner = () => {
  useEffect(()=>{
    Aos.init({duration: 2000});
  })
  return (
    <>
        <div className="py-md-5 py-4"/>
        <section id='website-responsive' className="container-fluid">
          <div className="row justify-content-center align-items-center">
            <div className="col-11 col-lg-10 col-xl-8 text-center">
              <div className="" data-aos="fade-up">
                <div className='fs-ss-35 fw-700 text-californian lh-sm'>
                Elegant, Stylish, and <br/> Sophisticated Residences
                </div>
                <div className='pt-3' />
                <div className="fs-ss-16 lh-lgh">
                  Welcome to Northlodge Boutique Residences, where sophistication meets exceptional convenience. Immerse yourself in the elegance of our remarkable amenities designed to upgrade your living experience. Our meticulously crafted apartments boast modern amenities and stylish finishes, providing comfort and style in every corner. At Northlodge, we prioritize your lifestyle preferences, ensuring you enjoy a living experience that perfectly suits your needs and desires.
                </div>
              </div>
            </div>
          </div>
        </section>
    </>
  );
}

export default BelowBanner;
