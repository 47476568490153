import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';

// Components
import Header from '../components/layout/Header';
import Footer2 from '../components/layout/Footer2';
import Icon1 from '../assets/images/register/Icon1.webp';
import Icon2 from '../assets/images/register/Icon2.webp';
import Icon3 from '../assets/images/register/Icon3.webp';
import Icon4 from '../assets/images/register/Icon4.webp';
import Call from '../assets/images/register/Call.webp';
import Email from '../assets/images/register/Email.webp';
import Address from '../assets/images/register/Address.webp';
import InquiryFormMain from '../components/register/InquiryFormMain';
import { TypeAnimation } from 'react-type-animation'

// Sources

const Register = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
        Register - Northlodge Boutique Residences
      </title>
    </Helmet>
    <Header/>
    <section id='website-responsive' className="container-fluid pt-5 mt-lg-5 ">
          <div className="row justify-content-evenly align-items-center text-center">
          <div className="col-11 col-lg-8 px-lg-5">
              <div className="">
              <div className=''>
              &nbsp;<TypeAnimation sequence={['Secure Your Dream Home',1000,'',200]}
                wrapper="span"
                speed={1}
                deletionSpeed={0}
                className='fs-ss-28 fw-500 lh-sm text-ss-secondary'
                repeat={Infinity}
                cursor={false} />
               
                </div>
                <div className='fs-ss-68 ls-2 fw-700 text-californian lh-sm pt-2'>
                Register Now!
                </div>
              </div>
          </div>
          </div>
        </section>

        <section className="container-fluid">
             <InquiryFormMain/>
        </section>

    <div className='border-0 z-back-page' >
    <iframe className='w-100 shadow' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2909.8653737840323!2d-79.2706199!3d43.170350000000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d3519e35032f3f%3A0x9a16913d05023ff5!2s20%20Black%20Walnut%20Common%2C%20St.%20Catharines%2C%20ON%20L2R%206P9%2C%20Canada!5e0!3m2!1sen!2s!4v1696998702934!5m2!1sen!2s" height="500"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
    
    <section id='website-responsive' className="container-fluid py-4 py-lg-5 px-5">
    <div className='fs-ss-35 fw-700 text-californian lh-sm pt-2 text-center'>
      Connect with us
    </div>
    <div className="row justify-content-center py-4 py-lg-5">

      <div className="col-3 col-lg-1"><a className='text-decoration-none text-ss-secondary' href="https://www.facebook.com/people/Premier-Developments/61552893976965/" target='_blank'><img src={Icon1} alt="" className="w-100 pe-2" /> </a> </div>
      <div className="col-3 col-lg-1"><a className='text-decoration-none text-ss-secondary' href="https://www.instagram.com/premierdevelopments.ca/" target='_blank'> <img src={Icon2} alt="" className="w-100 pe-2" /> </a> </div>
      <div className="col-3 col-lg-1"><a className='text-decoration-none text-ss-secondary' href="https://www.tiktok.com/@executive.homes" target='_blank'><img src={Icon3} alt="" className="w-100 pe-2" /> </a> </div>
      <div className="col-3 col-lg-1"><a className='text-decoration-none text-ss-secondary' href="https://www.youtube.com/@executivehomesrealtyinc.br8065/videos" target='_blank'><img src={Icon4} alt="" className="w-100 pe-2" /> </a> </div>
    </div>
    </section>
    <Footer2/>
    </>
  );
}
export default Register;
