import React from 'react';
import OneNavLink from './OneNavLink';

// Sources


const NavBar = () => {
  return (
    <>
    <OneNavLink 
    NavItem="Home"
    Link="/"
    />
  
    <OneNavLink 
    NavItem="Location"
    Link="/location"
    />
            <OneNavLink 
    NavItem="Brochure"
    Link="/brochure"
    />
        <OneNavLink 
    NavItem="Why Us"
    Link="/why-us"
    />
        <OneNavLink 
    NavItem="Gallery"
    Link="/gallery"
    />
        <OneNavLink 
    NavItem="Floor Plans"
    Link="/floor-plans"
    />
        <OneNavLink 
    NavItem="Register"
    Link="/register"
    />
    </>
  );
}

export default NavBar;
