import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";

// Sources


const OneNavLink = (props) => {
    // useEffect(() => {
        const location = useLocation();
        const { pathname } = location;
        const splitLocation = pathname.split("/");
        const pageName = "/"+splitLocation[1];
        // console.log(pageName);
        
    // }, []);
    return (
        <>
        <div className="col text-uppercase fw-500 fs-ss-16">
            {/* add active class with the active pageName */}
            <Link to={props.Link} className={`text-decoration-none ${pageName === props.Link ? "text-ss-secondary" : "text-black hover-sec"}`}>{props.NavItem}</Link>
            {/* <Link className='text-decoration-none text-black hover-sec'  to={props.Link}>
                {props.NavItem}
            </Link> */}

        
        </div>
        </>
    );
}

export default OneNavLink;
