import React from 'react';

// Sources
const Banner = (props) => {
  return (
    <>
      <div className="">
      <img className="d-none d-lg-block w-100" src={props.BannerImg} alt="" />
      <img className="d-block d-lg-none w-100" src={props.BannerImgMB} alt="" />
      </div>
    </>
  );
}

export default Banner;
