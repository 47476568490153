import React from 'react';

// Sources

const BottomFooter = () => {
  return (
    <>
        <section className="container-fluid py-1 bg-white fs-ss-14 text-black text-center">
        Copyright © 2024 Northlodge Boutique Residences. Powered by <a className='text-ss-secondary' href="https://zmcommunication.org/" target='_blank'> Z.M.Communication </a>
        </section>
    </>
  );
}

export default BottomFooter;
