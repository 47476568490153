import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import SuiteAImg1 from '../../assets/images/gallery/SuiteA/1.webp';
import SuiteAImg2 from '../../assets/images/gallery/SuiteA/2.webp';
import SuiteAImg3 from '../../assets/images/gallery/SuiteA/4.webp';
import SuiteAImg4 from '../../assets/images/gallery/SuiteA/6.webp';
import SuiteAImg5 from '../../assets/images/gallery/SuiteA/7.webp';
import SuiteAImg6 from '../../assets/images/gallery/SuiteA/9.webp';
import SuiteAImg7 from '../../assets/images/gallery/SuiteA/10.webp';
import SuiteAImg8 from '../../assets/images/gallery/SuiteA/11.webp';
import SuiteAImg9 from '../../assets/images/gallery/SuiteA/12.webp';
import SuiteAImg10 from '../../assets/images/gallery/SuiteA/13.webp';
const Gallery2 = () => {

const images = [
  {
    original: SuiteAImg1,
    thumbnail: SuiteAImg1,
  },
  {
    original: SuiteAImg2,
    thumbnail: SuiteAImg2,
  },
  {
    original: SuiteAImg3,
    thumbnail: SuiteAImg3,
  },
  {
    original: SuiteAImg4,
    thumbnail: SuiteAImg4,
  },
  {
    original: SuiteAImg5,
    thumbnail: SuiteAImg5,
  },
  {
    original: SuiteAImg6,
    thumbnail: SuiteAImg6,
  },
  {
    original: SuiteAImg7,
    thumbnail: SuiteAImg7,
  },
  {
    original: SuiteAImg8,
    thumbnail: SuiteAImg8,
  },
  {
    original: SuiteAImg9,
    thumbnail: SuiteAImg9,
  },
  {
    original: SuiteAImg10,
    thumbnail: SuiteAImg10,
  },
];

return (
  <>
  <section className="container-fluid">
    <section className="container-lg">
      <div className="row justify-content-center">
        <div className="col-11 col-md-8">
          <ImageGallery items={images} />
        </div>
      </div>
    </section>
  </section>
  </>
)
}

export default Gallery2;
