import React from 'react';
import HTMLFlipBook from 'react-pageflip';

// Components

import Img1 from '../../assets/images/broucher/new/1.webp';
import Img2 from '../../assets/images/broucher/new/2.webp';
import Img3 from '../../assets/images/broucher/new/3.webp';
import Img4 from '../../assets/images/broucher/new/4.webp';

import Img5 from '../../assets/images/broucher/new/5.webp';
import Img6 from '../../assets/images/broucher/new/6.webp';
import Img7 from '../../assets/images/broucher/new/7.webp';
import Img8 from '../../assets/images/broucher/new/8.webp';
import Img9 from '../../assets/images/broucher/new/9.webp';
import Img10 from '../../assets/images/broucher/new/10.webp';
import Img11 from '../../assets/images/broucher/new/11.webp';
import Img12 from '../../assets/images/broucher/new/12.webp';
import Img13 from '../../assets/images/broucher/new/13.webp';
import Img14 from '../../assets/images/broucher/new/14.webp';
import Img15 from '../../assets/images/broucher/new/15.webp';
import Img16 from '../../assets/images/broucher/new/16.webp';
import Img17 from '../../assets/images/broucher/new/17.webp';
// import Img18 from '../../assets/images/broucher/new/18.webp';
// import Img19 from '../../assets/images/broucher/new/19.webp';
// import Img20 from '../../assets/images/broucher/new/20.webp';


// Sources

const FlipMobile = () => {
  return (
    <>
        <section className="container-fluid d-block d-lg-none">
    <HTMLFlipBook  width={300} height={340}>
    <div className="demoPage text-white  fs-ss-12 ">
        <img className='w-100' src={Img1} alt="" />
        <div className='text-center bg-pg-nmb '>
        </div>
      </div>
      <div className="demoPage text-white  fs-ss-12">
        <img className='w-100' src={Img2} alt="" />
                <div className='text-center bg-pg-nmb '>
          Page 1  / 16
        </div>
      </div>
      <div className="demoPage text-white  fs-ss-12">
        <img className='w-100' src={Img3} alt="" />
                <div className='text-center bg-pg-nmb '>
          Page 2 / 16
        </div>
      </div>
      <div className="demoPage text-white  fs-ss-12">
        <img className='w-100' src={Img4} alt="" />
                <div className='text-center bg-pg-nmb '>
          Page 3 / 16
        </div>
      </div>
      <div className="demoPage text-white  fs-ss-12">
        <img className='w-100' src={Img5} alt="" />
                <div className='text-center bg-pg-nmb '>
          Page 4 / 16
        </div>
      </div>
      <div className="demoPage text-white  fs-ss-12">
        <img className='w-100' src={Img6} alt="" />
                <div className='text-center bg-pg-nmb '>
          Page 5 / 16
        </div>
      </div>
      <div className="demoPage text-white  fs-ss-12">
        <img className='w-100' src={Img7} alt="" />
                <div className='text-center bg-pg-nmb '>
          Page 6 / 16
        </div>
      </div>
      <div className="demoPage text-white  fs-ss-12">
        <img className='w-100' src={Img8} alt="" />
                <div className='text-center bg-pg-nmb '>
          Page 7 / 16
        </div>
      </div>
      <div className="demoPage text-white  fs-ss-12">
        <img className='w-100' src={Img9} alt="" />
                <div className='text-center bg-pg-nmb '>
          Page 8 / 16
        </div>
      </div>
      <div className="demoPage text-white  fs-ss-12">
        <img className='w-100' src={Img10} alt="" />
                <div className='text-center bg-pg-nmb '>
          Page 9 / 16
        </div>
      </div>
      <div className="demoPage text-white  fs-ss-12">
        <img className='w-100' src={Img11} alt="" />
                <div className='text-center bg-pg-nmb '>
          Page 10 / 16
        </div>
      </div>
      <div className="demoPage text-white  fs-ss-12">
        <img className='w-100' src={Img12} alt="" />
                <div className='text-center bg-pg-nmb '>
          Page 11 / 16
        </div>
      </div>
      <div className="demoPage text-white  fs-ss-12">
        <img className='w-100' src={Img13} alt="" />
                <div className='text-center bg-pg-nmb '>
          Page 12 / 16
        </div>
      </div>
      <div className="demoPage text-white  fs-ss-12">
        <img className='w-100' src={Img14} alt="" />
                <div className='text-center bg-pg-nmb '>
          Page 13 / 16
        </div>
      </div>
      <div className="demoPage text-white  fs-ss-12">
        <img className='w-100' src={Img15} alt="" />
                <div className='text-center bg-pg-nmb '>
          Page 14 / 16
        </div>
      </div>
      <div className="demoPage text-white  fs-ss-12">
        <img className='w-100' src={Img16} alt="" />
                <div className='text-center bg-pg-nmb '>
          Page 15 / 16
        </div>
      </div>
      <div className="demoPage text-white  fs-ss-12">
        <img className='w-100' src={Img17} alt="" />
                <div className='text-center bg-pg-nmb '>
          Page 16 / 16
        </div>
      </div>
      {/* <div className="demoPage text-white  fs-ss-12">
        <img className='w-100' src={Img17} alt="" />
                <div className='text-center bg-pg-nmb '>
          Page 17 / 16
        </div>
      </div>
      <div className="demoPage text-white  fs-ss-12">
        <img className='w-100' src={Img18} alt="" />
                <div className='text-center bg-pg-nmb '>
          Page 18 / 16
        </div>
      </div>
      <div className="demoPage text-white  fs-ss-12">
        <img className='w-100' src={Img19} alt="" />
                <div className='text-center bg-pg-nmb '>
          Page 19 / 16
        </div>
      </div>
      <div className="demoPage text-white  fs-ss-12">
        <img className='w-100' src={Img20} alt="" />
                <div className='text-center bg-pg-nmb '>
          Page 20 / 16
        </div>
      </div> */}
        </HTMLFlipBook>
        </section>
    </>
  );
}
export default FlipMobile;
