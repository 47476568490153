import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import SuiteFImg1 from '../../assets/images/gallery/SuiteF/3.webp';
import SuiteFImg2 from '../../assets/images/gallery/SuiteF/4.webp';
import SuiteFImg3 from '../../assets/images/gallery/SuiteF/5.webp';
import SuiteFImg4 from '../../assets/images/gallery/SuiteF/6.webp';
import SuiteFImg5 from '../../assets/images/gallery/SuiteF/7.webp';
import SuiteFImg6 from '../../assets/images/gallery/SuiteF/8.webp';
import SuiteFImg7 from '../../assets/images/gallery/SuiteF/1.webp';
import SuiteFImg8 from '../../assets/images/gallery/SuiteF/2.webp';
const Gallery2 = () => {

const images = [
  {
    original: SuiteFImg1,
    thumbnail: SuiteFImg1,
  },
  {
    original: SuiteFImg2,
    thumbnail: SuiteFImg2,
  },
  {
    original: SuiteFImg3,
    thumbnail: SuiteFImg3,
  },
  {
    original: SuiteFImg4,
    thumbnail: SuiteFImg4,
  },
  {
    original: SuiteFImg5,
    thumbnail: SuiteFImg5,
  },
  {
    original: SuiteFImg6,
    thumbnail: SuiteFImg6,
  },
  {
    original: SuiteFImg7,
    thumbnail: SuiteFImg7,
  },
  {
    original: SuiteFImg8,
    thumbnail: SuiteFImg8,
  },
];

return (
  <>
  <section className="container-fluid">
    <section className="container-lg">
      <div className="row justify-content-center">
        <div className="col-11 col-md-8">
          <ImageGallery items={images} />
        </div>
      </div>
    </section>
  </section>
  </>
)
}

export default Gallery2;
