import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import SlickSlider from "../components/slider/SlickSlider";
// Components
import Header from '../components/layout/Header';
import Footer2 from '../components/layout/Footer2';
import WhyUsBanner from '../components/whyus/WhyUsBanner';
import PopulationImg from '../../src/assets/images/whyus/PopulationImg.webp';
import SofaImg from '../../src/assets/images/whyus/SofaImg.webp';
import MiddleBanner from '../components/home/MiddleBanner';
import AnimationVideo from '../assets/images/whyus/MiddleBannerVideo.gif';
import AnimationVideoMB from '../assets/images/whyus/MiddleBannerVideoMB.gif';
import Img1 from '../assets/images/whyus/Img1.webp';
import Img2 from '../assets/images/whyus/Img2.webp';
import Img3 from '../assets/images/whyus/Img3.webp';
import Img4 from '../assets/images/whyus/Img4.webp';
import SliderAboveFooter from '../components/home/SliderAboveFooter';
// Sources

const WhyUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};
  return (
    <>
    <Helmet>
      <title>
        Why Us - Northlodge Boutique Residences
      </title>
    </Helmet>
    <Header/>
    <WhyUsBanner/>
    <div className="py-md-5 py-4"/>
    <section id='website-responsive' className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-lg-10 col-11">
          <div className='text-center fs-ss-35 fw-800 text-californian pb-3'>
          Why St. Catharines?  
          </div>
          <div>
            <div className="fs-ss-18 fw-700"> Location: <span className='fs-ss-16 fw-400'> St. Catharines, Niagara Peninsula </span> </div>
            <div className="fs-ss-18 fw-700 py-4"> Population: <span className='fs-ss-16 fw-400'> Over 140,000 in St. Catharines, with 33,000+ students at Brock University and Niagara College  </span>  </div>
            <div className="fs-ss-18 fw-700"> Expected Student Growth: <span className='fs-ss-16 fw-400'> Anticipated 13% increase (about 2,600 students) by 2024  </span>  </div>
          </div>
        </div>
      </div>
    </section>

    <div className="py-md-5 py-4"/>
    <section id='website-responsive' className="container-fluid bg">
      <div className="row justify-content-md-end align-items-center gy-4">
      <div className="col-11 col-lg-4 text-center text-lg-start mx-md-auto ps-xxl-5">
        <div className='ms-md-5 ps-xl-5'>
          <div className='fs-ss-40 fw-800 text-californian pb-3'>
          Accessibility  
          </div>
          <div className='text-ss-secondary fs-ss-22 fw-600 lh-lg'>
          Close to Niagara College, <br/>
          American Border (15 min), <br/>
          Niagara Falls (15 min), <br/>
          and Toronto (1 hr) <br/>
          </div>
          </div>
        </div>
        <div className="col-11 col-md-7 px-0">
        <SliderAboveFooter
            Slider1={<img className='w-100' src={Img1} alt="" />}
            Slider2={<img className='w-100' src={Img2} alt="" />}
            Slider3={<img className='w-100' src={Img3} alt="" />}
            Slider4={<img className='w-100' src={Img4} alt="" />}
            />
        </div>
      </div>
    </section>

    <div className="py-md-5 py-4"/>
    <section id='website-responsive' className="container-fluid">
      <div className="row justify-content-center">
        <div className="d-none d-lg-block col-4 position-whyus-parent">
          <img className='w-100 position-whyus-child' src={PopulationImg} alt="Population Img" />
        </div>
        <div className="col-11 col-lg-5 text-center text-lg-end">
          <div className="fs-ss-48 lh-sm fw-700 text-californian pb-lg-4 pt-4 py-4">
            Population <br/> Growth 
          </div>
          <div className='bg-ss-secondary text-white fs-ss-18 p-5 fw-600'>
            <div>
            5.32% increase over the last 10 years <br/><br/>
            0.72% increase over the previous 12 <br className='d-lg-block d-none'/> months (Statistics Canada) 
            </div>
          </div>
        </div>
        <div className="d-block d-lg-none col-11 col-lg-4">
          <img className='w-100' src={PopulationImg} alt="Population Img" />
        </div>
      </div>
    </section>

    <div className="py-md-5 py-4"/>
    <MiddleBanner
    AnimationVideo={AnimationVideo}
    AnimationVideoMB={AnimationVideoMB}
    />
    
    <div className="py-md-5 py-4"/>
    <section id='website-responsive' className="container-fluid">
      <div className="row justify-content-evenly align-items-center gy-4">
        <div className="d-none d-lg-block col-11 col-lg-3">
          <img className='w-100' src={SofaImg} alt="" />
        </div>
        <div className="col-11 col-lg-7">
        <div className="py-2 py-lg-4">
        <div className="fs-ss-30 fw-600">
        Property Prices:  
          </div>
          <div className="fs-ss-16">
          <li>AVG $/sqft St. Catharines: $865.00 </li>
          <li>AVG $/sqft Toronto: $1,400.00</li>
          </div>
          <hr />
          </div>

          <div className="py-2 py-lg-4">
        <div className="fs-ss-30 fw-600">
        Rental Value:  
          </div>
          <div className="fs-ss-16">
          <li>AVG 1 Bed: $1,800.00 </li>
          <li>AVG 2 Bed: $2,150.00</li>
          </div>
          <hr />
          </div>

          <div className="py-2 py-lg-4">
        <div className="fs-ss-30 fw-600">
        Competitive Condo Fee:
          </div>
          {/* <div className="fs-ss-16">
          <li>Monthly condo fees for NorthLodge TBD, but expected to be competitive. </li>
          </div> */}
          <hr />
          </div>

          <div className="py-2 py-lg-4">
        <div className="fs-ss-30 fw-600">
        Disclaimer:
          </div>
          <div className="fs-ss-16">
          <li> Developer's right to make changes without notice </li>
          </div>
          <hr />
          </div>
        </div>
        <div className="d-block d-lg-none col-11 col-lg-3">
          <img className='w-100' src={SofaImg} alt="" />
        </div>
      </div>
    </section>

    <div className="py-md-5 py-4"/>
    <section id='website-responsive' className="container-fluid pb-4">
          <div className="row justify-content-evenly align-items-center text-center">
          <div className="col-11 col-lg-8 px-lg-5">
              <div className="">
              <div className='fs-ss-18 fw-500 lh-sm text-ss-secondary'>
              Investment  
                </div>
                <div className='fs-ss-35 fw-700 text-californian lh-sm pt-2'>
                Opportunity
                </div>
                <div className='pt-3' />
                <div className="fs-ss-16 lh-lgh">
                NorthLodge Boutique Residences offers a strategic location, potential rental value, and competitive pricing compared to the GTA. Consider the dynamic real estate market and growth potential in St. Catharines.  
                </div>
              </div>
          </div>
          </div>
        </section>
        <section className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-10 col-lg-9">
            <SlickSlider/>
            </div>
          </div>
        </section>

        <div className="py-md-5 py-4"/>
    <Footer2/>
    </>
  );
}
export default WhyUs;
