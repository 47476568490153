import React, {useEffect} from 'react';
import SliderAboveFooter from './SliderAboveFooter';
import Aos from 'aos'
import Img1 from '../../assets/images/home/new_look/Img1.webp';
import Img2 from '../../assets/images/home/new_look/Img2.webp';
import Img3 from '../../assets/images/home/new_look/Img3.webp';
import Img4 from '../../assets/images/home/new_look/Img4.webp';
import Img5 from '../../assets/images/home/new_look/Img5.webp';
// Sources

const RightImgLeftText = () => {
  useEffect(()=>{
    Aos.init({duration: 2000});
  })
  return (
    <>
        <section id='website-responsive' className="container-fluid">
          <div className="row justify-content-evenly align-items-center text-center text-lg-start gy-4">
          <div className="col-11 col-lg-5">
              <div className="">
              <div className='fs-ss-18 fw-500 lh-sm text-ss-secondary'>
                The NorthLodge Boutique
                </div>
                <div className='fs-ss-35 fw-700 text-californian lh-sm pt-2'>
                Residences Collection
                </div>
                <div className='pt-3' />
                <div className="fs-ss-16 lh-lgh">
                Experience luxury at our exclusive enclave on the prestigious St. Catharines. Our handpicked premium units redefine lavish living, with every detail meticulously designed to surpass your expectations, ensuring an unmatched sophisticated living experience. 
                <div className='pt-2' />
                Don't miss out on the future of stylish living here in St. Catharines with our exceptional new builds. Secure your dream property today and commence your journey into luxurious condo living. 
                </div>
              </div>
          </div>
          <div className="col-11 col-sm-6 col-lg-4 text-center">
            <SliderAboveFooter
            Slider1={<img className='w-100' src={Img1} alt="" />}
            Slider2={<img className='w-100' src={Img2} alt="" />}
            Slider3={<img className='w-100' src={Img3} alt="" />}
            Slider4={<img className='w-100' src={Img4} alt="" />}
            Slider5={<img className='w-100' src={Img5} alt="" />}
            />
          </div>
          </div>
        </section>
    </>
  );
}

export default RightImgLeftText;
