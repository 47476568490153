import React from 'react';
import Banner from '../../assets/images/location/Banner.webp';
import BannerMobile from '../../assets/images/location/BannerMB.webp'; 



// Sources


const LocationBanner = () => {
  return (
    <>
    <section id="website-responsive">
    <img className='w-100 d-none d-lg-block' src={Banner} alt="" />
    <img className='w-100 d-block d-lg-none' src={BannerMobile} alt="" />
    {/* <div className="d-none d-lg-block row justify-content-start location-position-map gx-0">
    <div className="col-lg-6 col-11 bg-ss-primary ps-5 py-4 text-white location-position shadow">
    <div className='fs-ss-18 fw-500 lh-sm text-ss-secondary'>
      Invest in St. Catharines 
    </div>
    <div className='fs-ss-48 fw-700 text-californian lh-sm pt-2'>
      Where Beauty, Culture, and Opportunity Converge 
    </div>
    </div>
    </div> */}

    {/* <div className="d-flex d-lg-none row justify-content-center text-center pt-3">
    <div className="col-11 bg-ss-primary ps-3 py-4 text-white shadow">
    <div className='fs-ss-18 fw-500 lh-sm text-ss-secondary'>
      Invest in St. Catharines 
    </div>
    <div className='fs-ss-48 fw-700 text-californian lh-sm pt-2'>
      Where Beauty, Culture, and Opportunity Converge 
    </div>
    </div>
    </div> */}
    </section>
    </>
  );
}

export default LocationBanner;
