import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import SuiteBImg1 from '../../assets/images/gallery/SuiteB/2.webp';
import SuiteBImg2 from '../../assets/images/gallery/SuiteB/3.webp';
import SuiteBImg3 from '../../assets/images/gallery/SuiteB/4.webp';
import SuiteBImg4 from '../../assets/images/gallery/SuiteB/5.webp';
import SuiteBImg5 from '../../assets/images/gallery/SuiteB/1.webp';
import SuiteBImg6 from '../../assets/images/gallery/SuiteB/6.webp';
const Gallery2 = () => {

const images = [
  {
    original: SuiteBImg1,
    thumbnail: SuiteBImg1,
  },
  {
    original: SuiteBImg2,
    thumbnail: SuiteBImg2,
  },
  {
    original: SuiteBImg3,
    thumbnail: SuiteBImg3,
  },
  {
    original: SuiteBImg4,
    thumbnail: SuiteBImg4,
  },
  {
    original: SuiteBImg5,
    thumbnail: SuiteBImg5,
  },
  {
    original: SuiteBImg6,
    thumbnail: SuiteBImg6,
  },
];

return (
  <>
  <section className="container-fluid">
    <section className="container-lg">
      <div className="row justify-content-center">
        <div className="col-11 col-md-8">
          <ImageGallery items={images} />
        </div>
      </div>
    </section>
  </section>
  </>
)
}

export default Gallery2;
