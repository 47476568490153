import React from 'react';
import Img1 from '../../assets/images/location/1.webp';
import Img2 from '../../assets/images/location/2.webp';
import Img3 from '../../assets/images/location/3.webp';
import Img4 from '../../assets/images/location/4.webp';
import Img5 from '../../assets/images/location/5.webp';

// Sources


const StyleLocationSection2 = () => {
  return (
    <>
    <section id='website-responsive' className="container-fluid py-5 px-3">
      <div className="row justify-content-center">
      <div className="col-12 bg-ss-primary py-4 text-center px-3">
          <div className='align-items-center '>
            <div className='w-100 text-white pb-3'> 
            <div className="fs-ss-35 text-californian fw-500 pb-2">
            Wander the Picturesque Port
            </div>
            <div className="fs-ss-16 fw-400">
            Enjoy stunning marina views and the downtown skyline with boats dotting the harbor.
            </div>
            </div>
            <img className=' shadow rounded-3 w-75' src={Img1} alt="" />
          </div>
          <hr className='w-75 opacity-100 mx-auto text-white'/>
        </div>

        <div className="col-12 bg-ss-primary py-4 text-center px-3">
          <div className='align-items-center '>
            <div className='w-100 text-white pb-3 '> 
            <div className="fs-ss-35 text-californian fw-500 pb-2">
            Cultural Delights 
            </div>
            <div className="fs-ss-16 fw-400">
            Enjoy entertainment and culture at the First Ontario Performing Arts Centre.
            </div>
            </div>
            <img className=' shadow rounded-3 w-75' src={Img2} alt="" />
          </div>
          <hr className='w-75 opacity-100 mx-auto text-white'/>
        </div>

        <div className="col-12 bg-ss-primary py-4 text-center px-3">
          <div className='align-items-center '>
            <div className='w-100 text-white pb-3'> 
            <div className="fs-ss-35 text-californian fw-500 pb-2">
            Discover Niagara
            </div>
            <div className="fs-ss-16 fw-400">
            Experience the beauty of Niagara, from the iconic Niagara Falls to charming small waterfalls by historic mills. 
            </div>
            
            </div>
            <img className=' shadow rounded-3 w-75' src={Img3} alt="" />
          </div>
          <hr className='w-75 opacity-100 mx-auto text-white'/>
        </div>

        <div className="col-12 bg-ss-primary py-4 text-center px-3">
          <div className='align-items-center  '>
            <div className='w-100 text-white pb-3 '> 
            <div className="fs-ss-35 text-californian fw-500 pb-2">
            Embrace the Outdoors
            </div>
            <div className="fs-ss-16 fw-400">
            Explore the city's lush green spaces, including gardens, trails, and parks. 
            </div>
            </div>
            <img className=' shadow rounded-3 w-75' src={Img4} alt="" />
          </div>
          <hr className='w-75 opacity-100 mx-auto text-white'/>
        </div>

        <div className="col-12 bg-ss-primary py-4 text-center px-3">
          <div className='align-items-center '>
            <div className='w-100 text-white pb-3'> 
            <div className="fs-ss-35 text-californian fw-500 pb-2">
            Shop Local
            </div>
            <div className="fs-ss-16 fw-400">
            Find unique treasures in the local markets and boutiques. 
            </div>
            </div>
          </div>
          <img className=' shadow rounded-3 w-75' src={Img5} alt="" />
          <hr className='w-75 opacity-100 mx-auto text-white'/>
        </div>
      </div>
    </section>
    </>
  );
}

export default StyleLocationSection2;
