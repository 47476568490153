import React from 'react';
import NavBar from './NavBar';

// Sources


const BottomHeader = () => {
  return (
    <>
    <section id='navbar-bottom' className="container-fluid position-bottom-header z-front-page">
      <div className="row justify-content-center">
        <div className="col-8 d-flex bg-white text-center shadow py-3 rounded-2">
          <NavBar/>
        </div>
      </div>
    </section>
    </>
  );
}

export default BottomHeader;
