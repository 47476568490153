import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import SuiteCImg1 from '../../assets/images/gallery/SuiteC/1.webp';
import SuiteCImg2 from '../../assets/images/gallery/SuiteC/8.webp';
import SuiteCImg3 from '../../assets/images/gallery/SuiteC/3.webp';
import SuiteCImg5 from '../../assets/images/gallery/SuiteC/5.webp';
import SuiteCImg6 from '../../assets/images/gallery/SuiteC/6.webp';
import SuiteCImg7 from '../../assets/images/gallery/SuiteC/7.webp';
import SuiteCImg8 from '../../assets/images/gallery/SuiteC/2.webp';
import SuiteCImg9 from '../../assets/images/gallery/SuiteC/9.webp';
const Gallery2 = () => {

const images = [
  {
    original: SuiteCImg1,
    thumbnail: SuiteCImg1,
  },
  {
    original: SuiteCImg2,
    thumbnail: SuiteCImg2,
  },
  {
    original: SuiteCImg3,
    thumbnail: SuiteCImg3,
  },
  {
    original: SuiteCImg5,
    thumbnail: SuiteCImg5,
  },
  {
    original: SuiteCImg6,
    thumbnail: SuiteCImg6,
  },
  {
    original: SuiteCImg7,
    thumbnail: SuiteCImg7,
  },
  {
    original: SuiteCImg8,
    thumbnail: SuiteCImg8,
  },
  {
    original: SuiteCImg9,
    thumbnail: SuiteCImg9,
  },
];

return (
  <>
  <section className="container-fluid">
    <section className="container-lg">
      <div className="row justify-content-center">
        <div className="col-11 col-md-8">
          <ImageGallery items={images} />
        </div>
      </div>
    </section>
  </section>
  </>
)
}

export default Gallery2;
