import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';


// Components
import Header from '../components/layout/Header';
import Footer2 from '../components/layout/Footer2';
import FlipDesktop from '../components/brochure/FlipDesktop';
import FlipMobile from '../components/brochure/FlipMobile';
import brochurepdf from '../assets/images/broucher/broucher.pdf';


// Sources

const Brochure = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
      Brochure - Northlodge Boutique Residences
      </title>
    </Helmet>
    <Header/>
    <section className="container-fluid pt-5 mt-lg-4">
      <div className="row justify-content-evenly text-center">
        <div className="col-12 col-lg-11">
          <FlipDesktop/>
          <FlipMobile/>
        </div>
        <div className='pt-1 pt-lg-4 pb-5' style={{zIndex:2}}>
        <a className="" href={brochurepdf} target='_blank'>
        <button className='hover-golden-pri shadow py-2 px-4  fs-ss-18 border-0 mt-4'>
          Download Brochure
        </button>
        </a>
        </div>
        </div>
        </section>
    <Footer2/>
    </>
  );
}
export default Brochure;
