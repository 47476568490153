import React from 'react';
import Img1 from '../../assets/images/location/1.webp';
import Img2 from '../../assets/images/location/2.webp';
import Img3 from '../../assets/images/location/3.webp';
import Img4 from '../../assets/images/location/4.webp';
import Img5 from '../../assets/images/location/5.webp';

// Sources


const StyleLocationSection = () => {
  return (
    <>
        <section className="container-fluid py-xl-5 "></section>
    <section className="container-fluid py-4 py-lg-5">
      <div className="row justify-content-center">
      <div className="col-8 bg-ss-primary py-4">
          <div className='d-flex align-items-center position-img1-parent py-5'>
            <img className=' shadow rounded-3  position-img1-child w-25' src={Img1} alt="" />
            <div className='w-20'></div>
            <div className='w-65 text-white'> 
            <div className="fs-ss-35 text-californian fw-700 pb-2">
            Wander the Picturesque Port
            </div>
            <div className="fs-ss-16 fw-400">
            Enjoy stunning marina views and the downtown skyline with boats dotting the harbor.
            </div>
            <hr className='w-75 opacity-100'/>
            </div>
            <div className='w-15'></div>
          </div>
        </div>

        <div className="col-8 bg-ss-primary py-4">
          <div className='d-flex align-items-center position-img2-parent py-5'>
          <div className='w-15'></div>
            <div className='w-65 text-white text-end'> 
            <div className="fs-ss-35 text-californian fw-700 pb-2">
            Cultural Delights 
            </div>
            <div className="fs-ss-16 fw-400">
            Enjoy entertainment and culture at the First Ontario Performing Arts Centre.
            </div>
            <hr className='w-75 opacity-100 ms-auto'/>
            </div>
            <div className='w-20'></div>
            <img className=' shadow rounded-3  position-img2-child w-25' src={Img2} alt="" />

          </div>
        </div>

        <div className="col-8 bg-ss-primary py-4">
          <div className='d-flex align-items-center position-img3-parent py-5'>
            <img className=' shadow rounded-3  position-img1-child w-25' src={Img3} alt="" />
            <div className='w-20'></div>
            <div className='w-65 text-white'> 
            <div className="fs-ss-35 text-californian fw-700 pb-2">
            Discover Niagara
            </div>
            <div className="fs-ss-16 fw-400">
            Experience the beauty of Niagara, from the iconic Niagara Falls to charming small waterfalls by historic mills. 
            </div>
            <hr className='w-75 opacity-100'/>
            </div>
            <div className='w-15'></div>
          </div>
        </div>

        <div className="col-8 bg-ss-primary py-4">
          <div className='d-flex align-items-center position-img2-parent py-5'>
          <div className='w-15'></div>
            <div className='w-65 text-white text-end'> 
            <div className="fs-ss-35 text-californian fw-700 pb-2">
            Embrace the Outdoors
            </div>
            <div className="fs-ss-16 fw-400">
            Explore the city's lush green spaces, including gardens, trails, and parks. 
            </div>
            <hr className='w-75 opacity-100 ms-auto'/>
            </div>
            <div className='w-20'></div>
            <img className=' shadow rounded-3  position-img2-child w-25' src={Img4} alt="" />
          </div>
        </div>

        <div className="col-8 bg-ss-primary py-4">
          <div className='d-flex align-items-center position-img5-parent py-5'>
            <img className=' shadow rounded-3  position-img5-child w-25' src={Img5} alt="" />
            <div className='w-20'></div>
            <div className='w-65 text-white'> 
            <div className="fs-ss-35 text-californian fw-700 pb-2">
            Shop Local
            </div>
            <div className="fs-ss-16 fw-400">
            Find unique treasures in the local markets and boutiques. 
            </div>
            <hr className='w-75 opacity-100'/>
            </div>
            <div className='w-15'></div>
          </div>
        </div>
      </div>
    </section>
    <section className="container-fluid py-xxl-5 "></section>
    <section className="container-fluid py-xl-5 "></section>
    </>
  );
}

export default StyleLocationSection;
