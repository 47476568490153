import React from 'react';
import {Link} from 'react-router-dom';


// Sources

const MiddleBanner = (props) => {
  return (
    <>
            <section className="container-fluid px-0 middle-position-relative">
            <img src={props.AnimationVideo} className='mt-md-5 d-none d-lg-block w-100'>
          </img>
          <img src={props.AnimationVideoMB} className='mt-md-5 d-block d-lg-none w-100'>
          </img>
          <div className="middle-position d-none d-lg-block">
          <Link className='text-decoration-none' to="/register">
            <button className="fs-ss-18 hover-golden-white px-5 py-1 fw-300" style={{border:"1px solid #aa8332"}}>
                Register Now
            </button>
            </Link>
          </div>
            </section>

            <button className="mx-auto mb-4 d-block d-lg-none fs-ss-14 hover-golden-pri text-black mt-3 px-5 py-1 fw-300" style={{border:"1px solid #aa8332"}}>
            <Link className='text-decoration-none text-white' to="/register">
                Register Now
                </Link>
            </button>
    </>
  );
}

export default MiddleBanner;
