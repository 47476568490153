import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';

// Components
import Header from '../components/layout/Header';
import HomeBanner from '../components/home/HomeBanner';
import BelowBanner from '../components/home/BelowBanner';
import LeftImgRightText from '../components/home/LeftImgRightText';
import SuiteTour from '../components/home/SuiteTour';
import MiddleBanner from '../components/home/MiddleBanner';
import ChooseIdealUnit from '../components/home/ChooseIdealUnit';
import HeadingParaCenter from '../components/home/HeadingParaCenter';
import MapHome from '../components/home/MapHome';
import RightImgLeftText from '../components/home/RightImgLeftText';
import Footer2 from '../components/layout/Footer2';
import AnimationVideo from '../assets/images/home/Video.gif';
import HouseImg from '../assets/images/home/Img1.webp';
import AnimationVideoMB from '../assets/images/home/MiddleVideoMB.gif';

// Sources

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
        Home - Northlodge Boutique Residences
      </title>
    </Helmet>
    <Header/>
    <HomeBanner/>

    <section className="container-fluid px-0 bglogo1">
    <BelowBanner/>

    <div className="py-md-5 py-4"/>
    <section id='website-responsive' className="container-fluid bg">
          <div className="row justify-content-center align-items-center">
            <div className="col-11 col-lg-10 col-xl-8 text-center">
              <div className="">
              <div className='fs-ss-18 fw-500 lh-sm text-ss-secondary'>
                Experience the difference
                </div>
                <div className='fs-ss-35 fw-700 text-californian lh-sm pt-3'>
                Transforming Your Living Experience
                </div>
                <div className='pt-3' />
                <div className="fs-ss-16 lh-lgh">
                At Northlodge, we redefine luxury living with elegant suite interiors, stylish kitchens, and impeccable features and finishes. From cozy living rooms to modern and serene bedrooms, every detail is meticulously designed to enhance your lifestyle. We set you apart from the rest—all within the vibrant community of St. Catharines.
                </div>
              </div>
              <div className="pt-4 pt-md-5">
              <img className='w-100 shadow-ss-1' src={HouseImg} alt="" />
              </div>
            </div>
          </div>
      </section>
      </section>
      
    <div className="py-md-5 py-4"/>
    <ChooseIdealUnit/>

    <div className="py-md-5 py-4"/>
    <MiddleBanner
    AnimationVideo={AnimationVideo}
    AnimationVideoMB={AnimationVideoMB}/>

    <div className="py-md-5 py-4"/>
    <SuiteTour/>

    <div className="py-md-5 py-4"/>
    <HeadingParaCenter/>

    <div className="py-md-5 py-4"/>
    <MapHome/>

    <section className="container-fluid px-0 bglogo2">
    <div className="py-md-5 py-4"/>
    <RightImgLeftText/>
    <div className="py-md-5 py-4"/>
    </section>

    <Footer2/>
    </>
  );
}

export default Home;
