import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Img1 from '../assets/images/floorplan/new/1.webp';
import Img2 from '../assets/images/floorplan/new/2.webp';
import Img3 from '../assets/images/floorplan/new/3.webp';
import Img4 from '../assets/images/floorplan/new/4.webp';
import Img5 from '../assets/images/floorplan/new/5.webp';

// Components
import Header from '../components/layout/Header';
import Footer2 from '../components/layout/Footer2';
import Banner from '../components/banners/Banner';
import BannerImg from '../assets/images/floorplan/BannerImg.webp';
import BannerImgMB from '../assets/images/floorplan/BannerImgMB.webp';
import OnePlan from '../components/floor/OnePlan';

// Sources
import unitA from '../assets/images/floorplan/Unit-A-Grandham.pdf';
import unitB from '../assets/images/floorplan/Unit-B-Creekside.pdf';
import unitC from '../assets/images/floorplan/Unit-C-Lancaster.pdf';
import unitE from '../assets/images/floorplan/Unit-E-Embassy.pdf';
import unitF from '../assets/images/floorplan/Unit -F-Shipman.pdf';


const FloorPlans = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
        Floor Plans - Northlodge Boutique Residences
      </title>
    </Helmet>
    <Header/>
    <Banner
    BannerImg={BannerImg}
    BannerImgMB={BannerImgMB}
    />
    
    <div className="py-4 py-md-5" />
    <section id='website-responsive' className="container">
        <div className='fs-ss-35 fw-700 text-californian lh-sm pb-4 pb-md-5 text-center'>
          Floor Plans
          </div>
          <div className="row justify-content-center text-start align-items-center gx-2 gy-4">
            <OnePlan
            Img={Img1}
            Title="Unit A - Grandham"
            Pdf={unitA} 
            SubTitle="Suite Area: +/- 1,360 sq. ft."
            SubTitle1="3 Bedrooms, 3 Full Washrooms & Den"
            />

            <OnePlan
            Img={Img2}
            Title="Unit B - Creekside"
            Pdf={unitB}
            SubTitle="Suite Area: +/- 882 sq. ft."
            SubTitle1="2 Bedrooms, 2 Full Washrooms & Den"
            CustomSpace="margin-custom"
            />

            <OnePlan
            Img={Img3}
            Title="Unit C - Lancaster"
            Pdf={unitC}
            SubTitle="Suite Area: +/- 846 sq. ft"
            SubTitle1="2 Bedrooms, 2 Full Washrooms & Open Den"
            />
          </div>

          <div className="row justify-content-center text-start align-items-center gx-2 gy-4 margin-custom2">
            <OnePlan
            Img={Img4}
            Title="Unit E - Embassy"
            Pdf={unitE}
            SubTitle="Suite Area: 1,466 sq. ft."
            SubTitle1="3 Bedrooms, 3 Full Washrooms & Den"
            />

            <OnePlan
            Img={Img5}
            Title="Unit F - Shipman"
            Pdf={unitF}
            SubTitle="Suite Area: +/- 978 sq. ft."
            SubTitle1="2 Bedrooms, 3 Full Washrooms & Den"
            CustomSpace="margin-custom"
            />

            <div className="col-11 col-md-3" />
          </div>
        </section>

        <div className="py-4 py-md-5" />

    <Footer2/>
    </>
  );
}
export default FloorPlans;
