import React from 'react';
import Banner from '../../assets/images/whyus/Banner.webp';
import BannerMobile from '../../assets/images/whyus/BannerMobile.webp';

// Sources
const WhyUsBanner = () => {
  return (
    <>
      <div className="">
      <img className='w-100 d-none d-lg-block' src={Banner} alt="" />
      <img className='w-100 d-block d-lg-none' src={BannerMobile} alt="" />
      </div>
    </>
  );
}

export default WhyUsBanner;
