import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import SuiteEImg1 from '../../assets/images/gallery/SuiteE/8.webp';
import SuiteEImg2 from '../../assets/images/gallery/SuiteE/9.webp';
import SuiteEImg3 from '../../assets/images/gallery/SuiteE/3.webp';
import SuiteEImg4 from '../../assets/images/gallery/SuiteE/4.webp';
import SuiteEImg5 from '../../assets/images/gallery/SuiteE/5.webp';
import SuiteEImg6 from '../../assets/images/gallery/SuiteE/6.webp';
import SuiteEImg7 from '../../assets/images/gallery/SuiteE/7.webp';
import SuiteEImg8 from '../../assets/images/gallery/SuiteE/11.webp';
import SuiteEImg9 from '../../assets/images/gallery/SuiteE/1.webp';
import SuiteEImg10 from '../../assets/images/gallery/SuiteE/2.webp';
import SuiteEImg11 from '../../assets/images/gallery/SuiteE/10.webp';
const Gallery2 = () => {

const images = [
  {
    original: SuiteEImg1,
    thumbnail: SuiteEImg1,
  },
  {
    original: SuiteEImg2,
    thumbnail: SuiteEImg2,
  },
  {
    original: SuiteEImg3,
    thumbnail: SuiteEImg3,
  },
  {
    original: SuiteEImg4,
    thumbnail: SuiteEImg4,
  },
  {
    original: SuiteEImg5,
    thumbnail: SuiteEImg5,
  },
  {
    original: SuiteEImg7,
    thumbnail: SuiteEImg7,
  },
  {
    original: SuiteEImg8,
    thumbnail: SuiteEImg8,
  },
  {
    original: SuiteEImg6,
    thumbnail: SuiteEImg6,
  },
  {
    original: SuiteEImg9,
    thumbnail: SuiteEImg9,
  },
  {
    original: SuiteEImg10,
    thumbnail: SuiteEImg10,
  },
  {
    original: SuiteEImg11,
    thumbnail: SuiteEImg11,
  },
];

return (
  <>
  <section className="container-fluid">
    <section className="container-lg">
      <div className="row justify-content-center">
        <div className="col-11 col-md-8">
          <ImageGallery items={images} />
        </div>
      </div>
    </section>
  </section>
  </>
)
}

export default Gallery2;
