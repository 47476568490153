import React from 'react';

// Sources

const MapHome = () => {
  return (
    <>
    <div className='border-0 z-back-page' >
      <iframe className='w-100 shadow' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2909.8653737840323!2d-79.2706199!3d43.170350000000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d3519e35032f3f%3A0x9a16913d05023ff5!2s20%20Black%20Walnut%20Common%2C%20St.%20Catharines%2C%20ON%20L2R%206P9%2C%20Canada!5e0!3m2!1sen!2s!4v1696998702934!5m2!1sen!2s" height="500"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
    </>
  );
}

export default MapHome;
