import React, {useEffect} from 'react';
import Aos from 'aos'
// Sources

const HeadingParaCenter = () => {
  useEffect(()=>{
    Aos.init({duration: 2000});
  })
  return (
    <>
        <section id='website-responsive' className="container-fluid">
          <div className="row justify-content-evenly align-items-center text-center">
          <div className="col-11 col-lg-8 px-lg-5">
              <div className="" data-aos="fade-up">
              <div className='fs-ss-18 fw-500 lh-sm text-ss-secondary'>
                In the Heart of
                </div>
                <div className='fs-ss-35 fw-700 text-californian lh-sm pt-2'>
                Downtown St. Catharines
                </div>
                <div className='pt-3' />
                <div className="fs-ss-16 lh-lgh">
                  Explore more than exceptional healthcare in the Heart of Downtown St. Catharines. Immerse yourself in the vibrant urban environment, where every step leads to new experiences. From boutique shopping to cultural delights and tempting cafes, your journey to wellness begins in the heart of our bustling city. 
                </div>
              </div>
          </div>
          </div>
        </section>
    </>
  );
}

export default HeadingParaCenter;
