import React, {useState, useRef} from "react"
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from 'emailjs-com'
import RegisterImg from '../../assets/images/register/RegisterImg.webp'

const InquiryFormMain = () =>{
  const [verified, setVarified] = useState(false)
  function onChange(value) {
    console.log("Captcha value:", value);
    setVarified(true);
  }
  const refform = useRef();
  const sendForm = (e) =>{
    e.preventDefault();
  emailjs.sendForm('service_34mfhev','template_d2zhkdf',e.target,'vKG-XBLnS9Vj9_J16').then(res=>{
    console.log(res)
    alert("Your form has been submitted")
  }).catch(err=>{
    console.log(err)
  });
  e.target.reset()
};

    return (
    <>
      <form className="mt-lg-3 mb-4" ref={refform} onSubmit={sendForm} id="contactForm" method="POST" action="#">
        <div className="row justify-content-evenly align-items-center pb-lg-5 pt-lg-5 pt-3 gy-4 text-center">
        <div className="col-11 col-lg-5 px-3 d-none d-lg-block">
          <img src={RegisterImg} alt="" className="w-100" />
        </div>
        <div className="col-11 col-lg-5 px-3">
        <div className="form-group">
        <label className="invisible font-pri fw-600" for="first_name">First Name*</label>
        <input type="text" className="form-control bg-light py-2 rounded-4" id="first_name" name="first_name" placeholder="First Name*" required/>
      </div>
      <div className="form-group">
        <label className="invisible font-pri fw-600" for="last_name">Last Name*</label>
        <input type="text" className="form-control bg-light py-2 rounded-4" id="last_name" name="last_name" placeholder="Last Name*" required/>
      </div>
      <div className="form-group">
        <label className="invisible font-pri fw-600" for="phone_number">Phone Number*</label>
        <input type="text" className="form-control bg-light py-2 rounded-4" id="phone_number" name="phone_number" placeholder="Phone Number*" required/>
      </div>
      <div className="form-group">
        <label className="invisible font-pri fw-600" for="email">Email*</label>
        <input type="text" className="form-control bg-light py-2 rounded-4" id="email" name="email" placeholder="Email*" required/>
      </div>
      <div className="form-group">
        <label className="invisible font-pri fw-600" for="working_with_a_realtor?">Working with a Realtor?</label>
        <select className="form-select bg-light rounded-4 py-2" id="working_with_a_realtor" aria-label="Default select example">
        <option  className="form-select-child"selected>Working with a Realtor?*</option>
									<option value="Yes" className="form-select-child">Yes</option>
									<option value="No" className="form-select-child">No</option>
        </select>
      </div>
      <div className="form-group">
        <label className="invisible font-pri fw-600" for="if_yes_who_is_your_realtor?">If yes, Who is your Realtor?*</label>
        <input type="text" className="form-control bg-light py-2 rounded-4" id="if_yes_who_is_your_realtor?" name="if_yes_who_is_your_realtor?" placeholder="If yes, Who is your Realtor?*" required/>
      </div>
      <div className="pt-4 text-start">
        <div className="d-flex justify-content-start ">
      <ReCAPTCHA className="" 
sitekey="6Ld_qtQpAAAAAGN_gzN5S6BHRZ_Z2aHAvIlWhk3f" 
// <button className='w-100 btn-customization md-btn'>
// Submit
// </button>

    onChange={onChange}/>
            </div>
      <button className='fs-ss-18 my-4 px-5 hover-golden-pri py-1' disabled={!verified}>
            Submit
        </button>
      </div>
      </div>
      <div className="col-11 col-lg-5 px-3 d-block d-lg-none">
          <img src={RegisterImg} alt="" className="w-100" />
        </div>
      </div>
    </form>
    </>
    )
}

export default InquiryFormMain;