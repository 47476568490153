import React from 'react';
import { Link } from "react-router-dom"
import Navbar from 'react-bootstrap/Navbar';
// import { useLocation } from "react-router-dom";
import HeaderLogo from '../../assets/images/home/HeaderLogo.webp';
import Offcanvas from 'react-bootstrap/Offcanvas';


const MobileHeader = () => {
  // const location = useLocation();

  // const { pathname } = location;  
  // const splitLocation = pathname.split("/");

  return (
    <>
      <Navbar expand="lg" className="bg-ss-primary" id="website-nav">
        <div className='container-fluid'>
            <Navbar.Brand className='navbar-brand px-1 py-3 text-sm-end'>
              <Link
            className=" me-sm-4"
            to="/"
            >
            <img
                src={HeaderLogo}
                alt="Logo"
                className="d-none d-sm-inline w-50 me-sm-5 pe-sm-5"
            />
            <img
                src={HeaderLogo}
                alt="Logo"
                className="d-inline w-75 d-sm-none d-lg-inline pe-5"
            />
            </Link>
                
                
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} className='rounded-1 bg-white ms-5 fs-ss-12 px-2 mb-2 ' style={{border:"none"}} />
            </Navbar.Brand>
            <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="start"
            className="navbar-offcanvas"
            >
            <Offcanvas.Header closeButton className='bg-ss-primary'>

                <Offcanvas.Title className='' id={`offcanvasNavbarLabel-expand-lg`}>
                    <Link
                    className="flex-wrap navbar-brand d-flex align-items-baseline"
                    to="/"
                    >
                    <img
                        src={HeaderLogo}
                        alt="Logo"
                        className=""
                        style={{width: "228px"}}
                    />
                    </Link>
                </Offcanvas.Title>
            </Offcanvas.Header>
            {/* <hr /> */}
            <Offcanvas.Body>
            <ul className="navbar-nav d-block d-lg-none">
        <li className="nav-item">
          <Link className="nav-link hover-sec" aria-current="page" to="/">Home </Link>
        </li><span className='px-md-2'/>
        <li className="nav-item ">
          <Link  className="nav-link hover-sec" aria-current="page" to="/location">Location </Link>
        </li><span className='px-md-2'/>
        <li className="nav-item">
          <Link className="nav-link hover-sec" aria-current="page" to="/brochure"> Brochure </Link>
        </li><span className='px-md-2'/>
        <li className="nav-item">
          <Link className="nav-link hover-sec" aria-current="page" to="/why-us"> Why Us </Link>
        </li><span className='px-md-2'/>
        <li className="nav-item">
          <Link className="nav-link hover-sec" aria-current="page" to="/gallery"> Gallery </Link>
        </li><span className='px-md-2'/>
        <li className="nav-item ">
          <Link  className="nav-link hover-sec" aria-current="page" to="/floor-plans">Floor Plans </Link>
        </li><span className='px-md-2'/>
        {/* <li className="nav-item">
          <Link className="nav-link hover-sec" aria-current="page" to="/register"> Register </Link>
        </li><span className='px-md-2'/> */}
        <li className="mx-lg-4 border-white border-end">
        </li>
      <li>
      <div className=''>
        <Link className="" to="/register">
        <button className='hover-golden-pri shadow py-1 px-5  fs-ss-18 border-0 mt-4'>
         Register Now
        </button>
        </Link>
        </div>
      </li>
      </ul>

            </Offcanvas.Body>
            </Navbar.Offcanvas>
            </div>        
            </Navbar>
    </>
  )
}

export default MobileHeader