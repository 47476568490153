import React, { Component } from "react";
import Slider from "react-slick";
import {Link} from 'react-router-dom';

const SliderAboveFooter = (props) => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    
  };
    return (
      <>
      <section id='website-responsive' className="container-fluid px-0">
      <div id="home-banner" className="">
        <Slider {...settings}>
          {props.Slider1}
          {props.Slider2}
          {props.Slider3}
          {props.Slider4}
          {props.Slider5}
          {props.Slider6}
          {props.Slider7}
          {props.Slider8}
          {props.Slider9}
          {props.Slider10}
          {props.Slider11}
          {props.Slider12}
          {props.Slider13}
        </Slider>
      </div>
        </section>
      </>
    );
  }

export default SliderAboveFooter;
