import React from 'react';
import {Routes, Route} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap'
import '../src/assets/css/style.css';
import "aos/dist/aos.css"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import 'react-tabs/style/react-tabs.css';

// Pages
import Home from './pages/Home'
import Location from './pages/Location';
import Register from './pages/Register';
import WhyUs from './pages/WhyUs';
import Gallery from './pages/Gallery';
import FloorPlans from './pages/FloorPlans';
import Brochure from './pages/Brochure';
import Error404 from './pages/Error404';



const App = () => {
  return (
    <>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/location" element={<Location/>} />
          <Route path="/register" element={<Register/>} />
          <Route path="/why-us" element={<WhyUs/>} />
          <Route path="/gallery" element={<Gallery/>} />
          <Route path="/floor-plans" element={<FloorPlans/>} />
          <Route path="/brochure" element={<Brochure/>} />
          <Route path="*" element={<Error404/>} />
        </Routes>
        <ToastContainer
              position="bottom-left"
              autoClose={5000}
          />
    </>
  );
}

export default App;
