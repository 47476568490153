import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';

// Components
import Header from '../components/layout/Header';
import Footer2 from '../components/layout/Footer2';
import LocationBanner from '../components/location/LocationBanner';
import Img1 from '../assets/images/location/Img1.webp';
import Img2 from '../assets/images/location/Img2.webp';
import Img3 from '../assets/images/location/Img3.webp';
import Img4 from '../assets/images/location/Img4.webp';
import Img5 from '../assets/images/location/Img5.webp';
import StyleLocationSection from '../components/location/StyleLocationSection';
import StyleLocationSection2 from '../components/location/StyleLocationSection2';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import brochurepdf from '../assets/images/broucher/broucher.pdf';

// Sources

const Location = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])


var settings = {
  dots: false,
  infinite: true,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: false
};

  return (
    <>
    <Helmet>
      <title>
        Location - Northlodge Boutique Residences
      </title>
    </Helmet>
    <Header/>
    <LocationBanner/>

    <div className="py-md-5 py-4"/>

    <section id='website-responsive' className="container-fluid">
          <div className="row justify-content-center align-items-center">
            <div className="col-11 col-lg-10 col-xl-8 text-center">
              <div className="">
              <div className='fs-ss-18 fw-500 lh-sm text-ss-secondary'>
                Invest in St. Catharines 
                </div>
                <div className='fs-ss-35 fw-700 text-californian lh-sm pt-3'>
                Where Beauty, Culture, and <br/> Opportunity Converge 
                </div>
              </div>
            </div>
          </div>
    </section>

    <section id='website-responsive' className="container-fluid text-californian bg py-md-5">
      <div id="home-banner" className="row justify-content-center">
        <div className="col-12 col-md-8">
        <Slider {...settings} className="">

          <div className="position-parent">
          <img className='w-100' src={Img1} alt="" />
          <div className="fs-ss-banner-location col-4 ms-auto position-child-location">
          <div className="text-ss-primary">
          The eighth fastest-growing municipality in <span className="text-ss-secondary"> Canada </span>
          </div>
          </div>
          </div>

          <div className="position-parent">
          <img className='w-100' src={Img2} alt="" />
          <div className="fs-ss-banner-location col-4 ms-auto position-child-location">
          <div className="text-ss-primary">
          Predicted 25% growth in the Niagara Region over the next <span className="text-ss-secondary"> decade </span> 
          </div>
          </div>
          </div>

          <div className="position-parent">
          <img className='w-100' src={Img3} alt="" />
          <div className="fs-ss-banner-location col-4 ms-auto position-child-location">
          <div className="text-ss-primary">
          15-minute drive to the <span className="text-ss-secondary"> American border </span>  
          </div>
          </div>
          </div>

          <div className="position-parent">
          <img className='w-100' src={Img4} alt="" />
          <div className="fs-ss-banner-location col-4 ms-auto position-child-location">
          <div className="text-ss-primary">
          10-minute drive to <span className="text-ss-secondary"> Brock University </span> 
          </div>
          </div>
          </div>

          <div className="position-parent">
          <img className='w-100' src={Img5} alt="" />
          <div className="fs-ss-banner-location col-4 ms-auto position-child-location">
          <div className="text-ss-primary">
          1-hour drive from Toronto, <span className="text-ss-secondary"> Ontario </span> 
          </div>
          </div>
          </div>
        </Slider>

        </div>

        <div className='text-center text-poppins'>
        <a className="" href={brochurepdf} target='_blank'> 
        <button className='hover-golden-pri shadow py-2 px-4 fs-ss-18 border-0'>
          Download Brochure
        </button>
        </a>
        </div>

        </div>
        </section>
        
        <div className="py-md-5 py-4"/>

    {/* Desktop */}
    <section className="container-fluid d-none d-lg-block">
    <StyleLocationSection/>
    </section>
    
    {/* Mobile */}
    <section className="container-fluid d-block d-lg-none">
    <StyleLocationSection2/>
    </section>
    <Footer2/>
    </>
  );
}
export default Location;
