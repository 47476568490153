import React from 'react';
import ChooseIdealUnitImg from '../../assets/images/home/ChooseIdealUnitImg.png';
import {Link} from 'react-router-dom';

// Sources

const ChooseIdealUnit = () => {
  return (
    <>
        <section id='website-responsive' className="container-fluid">
          <div className="row justify-content-evenly align-items-center gy-4 text-center text-lg-start">
          <div className="col-11 col-lg-5">
              <div className="">
              <div className='fs-ss-18 fw-500 lh-sm text-ss-secondary'>
                Choose
                </div>
                <div className='fs-ss-35 fw-700 text-californian lh-sm pt-2'>
                Your Ideal Unit
                </div>
                <div className='pt-3' />
                <div className="fs-ss-16 lh-lgh">
                Select from a diverse range of unit options, including 2-3 bedrooms with den, and configurations with studies and dens. Each unit is uniquely named, reflecting the essence of St. Catharines – Merritt, Regatta, Brock, Falls, Catharine, and more. 
                </div>
                <button className="fs-ss-18 hover-golden-pri text-black mt-4 px-4 py-2 fw-300" style={{border:"1px solid #aa8332"}}>
            <Link className='text-decoration-none text-white' to="/floor-plans">
            View Floor Plans
                </Link>
            </button>
              </div>
          </div>
          <div className="col-11 col-lg-5">
              <img className='w-100' src={ChooseIdealUnitImg} alt="" />
            </div>
          </div>
        </section>
    </>
  );
}

export default ChooseIdealUnit;
