import React from 'react';
import {Link} from 'react-router-dom';
import BottomFooter from '../layout/BottomFooter';
import FooterLogo from '../../assets/images/home/HeaderLogo.webp';
import { EnvelopeFill, Facebook, Instagram, Linkedin, TelephoneFill, Tiktok, Twitter, Youtube} from 'react-bootstrap-icons';

// Sources
const Footer2 = () => {
  return (
    <>
        <section id='website-responsive' className="container-fluid bg-footer text-center py-4 py-md-5 pb-3">
          <div className="row justify-content-center">
            <div className="col-10 col-md-4">
              <img className='w-100' src={FooterLogo} alt="Footer Logo" />
              {/* <div className='pt-4 text-white fs-ss-14'>
              Experience the pinnacle of affordable luxury at NorthLodge Boutique Residences, where style meets comfort in the heart of downtown St. Catharines. Join us on this journey to redefine your living standards.
              </div> */}
            </div>
            <div className="pt-4" />
            <div className="col-11 col-md-10">
              <div className="">
              <Link className='text-decoration-none' to="/register">
              <button className="fs-ss-16 hover-golden-white mt-md-3 px-4 py-1 fw-500 border-0">
                Register Now
              </button>
              </Link>
              </div>
              <div className="pt-4" />
              <div className="d-block d-md-flex justify-content-center text-white">
              <div className="mb-1 mb-lg-2"> 
               <TelephoneFill className='me-2'/> <a className='text-decoration-none text-white' href="tel:905-890-1300" target='_blank'> 905-890-1300 </a> 
               </div>
               <div className="px-3"/>
               <div className="mb-1 mb-lg-2"> 
               <EnvelopeFill className='me-2'/> <a className='text-decoration-none text-white' href="mailto:sales@northlodge.ca" target='_blank'> sales@northlodge.ca </a> 
              </div>
              </div>
              <div className="pt-4" />
              <div className="text-ss-secondary fs-ss-30">
                <a className='text-decoration-none text-ss-secondary' href="https://www.facebook.com/people/Premier-Developments/61552893976965/" target='_blank'> <Facebook className='me-3 '/></a>
                <a className='text-decoration-none text-ss-secondary' href="https://www.instagram.com/premierdevelopments.ca/" target='_blank'> <Instagram className='me-3 '/></a>
                <a className='text-decoration-none text-ss-secondary' href="https://www.tiktok.com/@executive.homes" target='_blank'> <Tiktok className='me-3 '/></a>
                <a className='text-decoration-none text-ss-secondary' href="https://www.youtube.com/@executivehomesrealtyinc.br8065/videos" target='_blank'> <Youtube className=''/></a>
              </div>
              <div className="pt-4" />
              <div className="d-none d-md-flex text-white fs-ss-16 justify-content-md-center">
                  <div className="mx-md-5 me-3"> <Link className='text-decoration-none text-white' to="/"> Home </Link> </div>
                  <div className="me-md-5 me-3"> <Link className='text-decoration-none text-white' to="/location"> Location  </Link> </div>
                  <div className="me-md-5 me-3"> <Link className='text-decoration-none text-white' to="/brochure"> Brochure </Link> </div>
                  <div className="me-md-5"> <Link className='text-decoration-none text-white' to="/why-us">  Why Us  </Link> </div>
                  <div className="me-md-5"> <Link className='text-decoration-none text-white' to="/gallery">  Gallery  </Link> </div>
                  <div className="me-md-5"> <Link className='text-decoration-none text-white' to="/floor-plans">  Floor Plans  </Link> </div>
                  <div className="me-md-5"> <Link className='text-decoration-none text-white' to="/register">  Register  </Link> </div>
              </div>
              <div className="d-flex d-md-none row justify-content-center">
                  <div className="col px-0"> <Link className='text-decoration-none text-white' to="/location"> Location  </Link> </div>
                  <div className="col px-0"> <Link className='text-decoration-none text-white' to="/why-us">  Why Us  </Link> </div>
                  <div className="col px-0"> <Link className='text-decoration-none text-white' to="/gallery">  Gallery  </Link> </div>
                  <div className="col px-0"> <Link className='text-decoration-none text-white' to="/register">  Register  </Link> </div>
              </div>
              <div className="pt-md-2" />
              </div>
          </div>
        </section>
        <BottomFooter/>
    </>
  );
}

export default Footer2;
