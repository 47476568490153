
const OnePlan = (props) => {
    return (
        <>
        <div className={`col-11 col-md-3 pattern-plans-1 py-1 rounded-3 shadow-ss-1 ${props.CustomSpace}`}>
            <div className="">
              <img className='w-100' src={props.Img} alt="" />
            </div>
            <div className='text-white align-self-center fs-ss-20 text-center py-3'>
              <a className="text-white fw-600 text-decoration-none" href={props.Pdf} target='_blank'>{props.Title} </a>
              <div className='fs-ss-12 pt-2'>
              {props.SubTitle}
              </div>
              <div className='fs-ss-12'>
              {props.SubTitle1}
              </div>
            </div>
          </div>
        </>
    );
}

export default OnePlan;
