import React from 'react';
import SuitTourImg from '../../assets/images/home/SiteTourImg.webp';

// Sources

const SuiteTour = () => {
  return (
    <>
        <section id='website-responsive' className="container-fluid bg">
        <div className='fs-ss-35 fw-700 text-californian lh-sm pb-4 text-center'>
                Suite Tour
                </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-11 col-lg-10">
              <img className='w-100' src={SuitTourImg} alt="Suit Tour Image" />
            </div>
          </div>
        </section>
    </>
  );
}

export default SuiteTour;
