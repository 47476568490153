import React, { Component } from "react";
import Slider from "react-slick";
import Img1 from '../../assets/images/whyus/1.webp';
import Img2 from '../../assets/images/whyus/2.webp';
import Img3 from '../../assets/images/whyus/3.webp';
import Img4 from '../../assets/images/whyus/4.webp';
import Img5 from '../../assets/images/whyus/5.webp';
import Img6 from '../../assets/images/whyus/6.webp';
import Img7 from '../../assets/images/whyus/7.webp';
import Img8 from '../../assets/images/whyus/8.webp';
import Img9 from '../../assets/images/whyus/9.webp';
import Img10 from '../../assets/images/whyus/10.webp';
import Img11 from '../../assets/images/whyus/11.webp';
import Img12 from '../../assets/images/whyus/12.webp';
import Img13 from '../../assets/images/whyus/13.webp';

export default class Responsive extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 0,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div className="">
        <Slider {...settings}>
        <div className="">
    <img src={Img1} className="border-brown w-90 mx-auto shadow-img" alt="..."/>
    </div>
    <div>
    <img src={Img2} className="border-brown w-90 mx-auto shadow-img" alt="..."/>
    </div>
    <div>
    <img src={Img3} className="border-brown w-90 mx-auto shadow-img" alt="..."/>
    </div>
    <div>
    <img src={Img4} className="border-brown w-90 mx-auto shadow-img" alt="..."/>
    </div>
    <div>
    <img src={Img5} className="border-brown w-90 mx-auto shadow-img" alt="..."/>
    </div>
    <div>
    <img src={Img6} className="border-brown w-90 mx-auto shadow-img" alt="..."/>
    </div>
    <div>
    <img src={Img7} className="border-brown w-90 mx-auto shadow-img" alt="..."/>
    </div>
    <div>
    <img src={Img8} className="border-brown w-90 mx-auto shadow-img" alt="..."/>
    </div>
    <div>
    <img src={Img9} className="border-brown w-90 mx-auto shadow-img" alt="..."/>
    </div>
    <div>
    <img src={Img10} className="border-brown w-90 mx-auto shadow-img" alt="..."/>
    </div>
    <div>
    <img src={Img11} className="border-brown w-90 mx-auto shadow-img" alt="..."/>
    </div>
    <div>
    <img src={Img12} className="border-brown w-90 mx-auto shadow-img" alt="..."/>
    </div>
    <div>
    <img src={Img13} className="border-brown w-90 mx-auto shadow-img" alt="..."/>
    </div>
        </Slider>
      </div>
    );
  }
}