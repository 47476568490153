import React, { useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Gallery1 from './Gallery1';
import Gallery2 from './Gallery2';
import Gallery3 from './Gallery3';
import Gallery4 from './Gallery4';
import Gallery5 from './Gallery5';

const GallaryHeader = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
  }
const handleClick = (btn_id) => {
    setID(btn_id)
    console.log('btn_id',btn_id)
}

    const [id,setID] = useState(1)

  return (
    <>
    <div id='website-responsive' className=''>
    <Tabs>

    <TabList className="text-center fs-ss-16 fw-600 pt-4 mx-auto">
      <Tab> 
        <div className="">
          <div className='fs-ss-16 fw-500 lh-sm text-ss-secondary'>
            Suite A
          </div>
          <div className='fs-ss-22 fw-600 text-poppins lh-sm pt-2'>
            Grandham
          </div>
        </div>
      </Tab>

      <Tab> 
        <div className="">
          <div className='fs-ss-16 fw-500 lh-sm text-ss-secondary'>
            Suite B
          </div>
          <div className='fs-ss-22 fw-600 text-poppins lh-sm pt-2'>
          Creekside
          </div>
        </div>
      </Tab>

      <Tab> 
        <div className="">
          <div className='fs-ss-16 fw-500 lh-sm text-ss-secondary'>
            Suite C
          </div>
          <div className='fs-ss-22 fw-600 text-poppins lh-sm pt-2'>
          Lancaster
          </div>
        </div>
      </Tab>

      <Tab> 
        <div className="">
          <div className='fs-ss-16 fw-500 lh-sm text-ss-secondary'>
            Suite E
          </div>
          <div className='fs-ss-22 fw-600 text-poppins lh-sm pt-2'>
          Embassy
          </div>
        </div>
      </Tab>

      <Tab> 
        <div className="">
          <div className='fs-ss-16 fw-500 lh-sm text-ss-secondary'>
            Suite F
          </div>
          <div className='fs-ss-22 fw-600 text-poppins lh-sm pt-2'>
          Shipman
          </div>
        </div>
      </Tab>
      
    </TabList>

    <section className="container-fluid bg px-0 py-md-5">
    <TabPanel>
      <Gallery1/>
    </TabPanel>

    <TabPanel>
      <Gallery2/>
    </TabPanel>

    <TabPanel>
      <Gallery3/>
    </TabPanel>

    <TabPanel>
      <Gallery4/>
    </TabPanel>

    <TabPanel>
      <Gallery5/>
    </TabPanel>
    </section>

  </Tabs>  
    </div>
    </>
  )
}

export default GallaryHeader